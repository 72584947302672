.back-to-top-button {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.23);
    z-index: 9999;
  }
  
.back-to-top-button.visible {
  display: block;
}

.back-to-top-button:hover {
  background-color: #007fff;
}

.arrow {
  font-size: 20px;
}