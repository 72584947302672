.research-card {
    background-color: #007fff;
    border: 1px solid #ddd;
    padding: 15px;
    transition: transform 0.2s;
    max-height: 15px;
    align-content: center;
    justify-content: space-between;
    text-align: center;
    color: white;
    display: block;
}

.research-text {
    margin: -2px 0 0 0;
    padding: 0;
    font-weight: bold;
}
.research-card:hover {
    transform: scale(1.01);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #EB9F42;
}


@media (max-width: 1080px) {
    .research-card {
        padding: none;
        align-content: center;  
    }
    .research-text {
        font-size: 12px;
    }
}