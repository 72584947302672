.header {
  background: none;
  color: grey;
  width: 100vw;
  display: flex;
  z-index: 999;
  height: 80px;
  align-items: center; 
  justify-content: space-between; 
  padding: 0;
  transition: 0.3s ease;
  position: fixed;
}

.header-link {
  text-decoration: none;
  color: white;
  margin-left: 20px;
  transition: all 0.3s ease;
}

.scrolled-text {
  color: #333;
  transition: color 0.3s ease;
}

.header-link:hover {
  color: #888888;
}
  
.nav {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  letter-spacing: 2px;
}

.a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.a:hover {
  background-color: grey;
  color: white;
}

.a.active{
  background-color: grey;
  color: white;
}

.scrolled-a {
  text-decoration: none;
  color: rgb(100, 100, 100);
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.scrolled-a:hover{
  background-color: rgb(100, 100, 100);
  color: white;
}

.header-button {
  border: 2px solid white;
  color: white;
  background-color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
}

.header-button-scrolled {
  border: 2px solid black;
  color: black;
  background-color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
}

.header-button-scrolled:hover {
  border: 2px solid black !important;
  background-color: black !important;
  color: white !important;
} 

.header-button:hover {
  border: 2px solid white;
  background-color: white;
  color: black;
}

.scrolled {
  background-color: white !important;
  transition: background-color 0.3s ease;
  color: white;
  width: 100vw;
  height: 60px;
  position: fixed;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  width: 100vw;
} 

.mobile-menu {
  display: none;
}

.mobile-menu-button {
  display: none;
}

.mobile-menu-close-button {
  display: none;
}

.scrolled-mobile-menu-button {
  display: none;
}

.camera-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}

.camera-div-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.camera-icon {
  color: white;
  margin-left: -10px !important;
}

.camera-icon:hover {
  color: grey;
  transition: all 0.3s ease;
}

.scrolled-camera-icon {
  color: black;
}

.scrolled-camera-icon:hover {
  color: white;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1080px) {
  .nav {
    display: none;
  }

  .mobile-menu-button {
    display: block;
    margin-right: 3%;
    background: none;
    color: white;
    font-size: 24px;
    box-shadow: none;
    border: none;
    position: fixed;
    float: right;
    top: 25px;
    right: 0;
    z-index: 9999;
  }

  .mobile-menu-button.menu-button-open {
    display: none !important;
    opacity: 0;
  }

  .scrolled-mobile-menu-button {
    display: block;
    top: 17px;
    right: 0;
    background: none;
    color: black;
    font-size: 24px;
    box-shadow: none;
    border: none;
  }

  .mobile-menu-close-button {
    display: none;
  }

  .mobile-menu-close-button.scrolled-mobile-menu-close-button.close-button-open {
    display: block;
    top: 17px;
    right: 0;
    background: none;
    color: black;
    font-size: 24px;
    box-shadow: none;
    border: none;
  }

  .mobile-menu-close-button.close-button-open {
    display: block;
    margin-right: 3%;
    background: none;
    color: black;
    font-size: 24px;
    box-shadow: none;
    border: none;
    position: fixed;
    float: right;
    top: 25px;
    right: 0;
    z-index: 9999;
  }

  .mobile-menu.open {
    position: fixed;
    float: right;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.99;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: white;
    border: none;
    text-align: center;
    height: 100vh;
    justify-content: center;
    align-content: center;
    overflow: visible;
    transform: translateX(0%);
    transition: all 0.3s ease;
    
  }

  .mobile-menu-nav {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .scrolled-a {
    color: black;
  }

  .scrolled-a:hover {
    background-color: grey;
  }

  .camera-icon {
    color: black !important;
  }
}


