.main-div-home {
    text-align: center;
}

.pretitle-font{
    letter-spacing: 2px;;
}

.timeline-main {
    margin-right: 10%;
    margin-left: 10%;
}

.home-headers {
    margin-top: 40px; 
    color: black;
}

#home {
    
    background-image: url('../assets/ai_video.gif');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; 
    position: relative;
    height: 90vh; 
    color: #ffffff;
    text-align: center;
    padding: 100px 0;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
  
.hero h1 {
    font-size: 5rem;
    margin: 0;
}

.hero p {
    font-size: 1.2rem;
    margin: 20px 20%;
}

.academic-hero {
    margin-top: 20px !important;
}

.academic-hero a{
    color: white !important;
}

.academic-hero a:hover{
    transform: scale(1.2) !important;
}
.about p{
    margin: 0 10%;
}

.sections-home {
    margin-top: 120px;
}

.about h3 {
    font-size: 1.25rem;
    padding: 10px 0 0 0;
}

.intro-grid{
    display: grid;
    grid-template-columns: 25% 75%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 40px;
}

.intro-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-image {
    width: 100%;
    border-radius: 50%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
}

.intro-image:hover {
    transform: scale(1.05) rotate(5deg);;
}

.intro-text-container {
    text-align: left;
}

.research-card-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 25px;
    text-align: center;
    align-content: center;
    justify-content: space-between;
    padding: 10px 10%;
}

.intro-text-codes {
    color: #007fff;
    font-weight: bold;
}

.projects-main {
    margin-left: 10%;
    margin-right: 10%;
}

@media (max-width: 1080px) {
    .intro-grid {
      grid-template-columns: 1fr;
      text-align: center; 
    }

    .intro-image {
        max-width: 90%;
        margin-bottom: 40px;
        width: 50%;
    }

    .intro-text-container {
        text-align: center;
    }

    .about p {
        margin: 0 !important;
    }

    .research-card-section {
        grid-template-columns: repeat(2, 1fr);
    }
}