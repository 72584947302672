.chrono-icons {
    size: 24px;
}

.timeline-main-wrapper {
    pointer-events: none !important;
    min-width: 100%;
}

.using-icon img {
    max-height: 400% !important;
    max-width: 400% !important;
    border: 1px solid black;
    border-radius: 50%;
}

.timeline-item-title.rc-title.active {
    background: none !important;
    color: #EB9F42 !important;
    border: none !important;
}

.timeline-item-title.rc-title {
    color: #EB9F42 !important;
}

.timeline-card-content.rc-card{
    pointer-events: none !important;
}

@media (max-width: 1080px) {
    .timeline-item-title.rc-title {
        padding-right: 5px;
        font-size: 14px;
    }

    .timeline-card-content.rc-card {
        margin-left: 20px;
    }

    .right {
        margin-left: 5px;
    }

    .using-icon img {
        max-height: 275% !important;
        max-width: 275% !important;
        border: 1px solid black;
        border-radius: 50%;
    }

}
