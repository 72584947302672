.footer {
    width: 100 vw;
    padding: none;
    margin: none;
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: #fff;
    color: #000;
    height: 100px;
    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.2);;
}

.footer-content {
    display: flex;
    width: 90vw;
    align-items: center;
    justify-content: center;
}

.footer-column {
    display: flex;
    flex: 1;
    text-align: center;
    align-content: center;
    justify-content: center;
    line-height: 1.2rem;
}
/* Style the copyright notice */
.footer p {
    font-size: 14px;

}

.email {
    color: black;
    margin: 10px; 
    font-size: 24px;
    text-decoration: none;
    transition: all 0.3s;   
}

.email:hover {
    transform: rotate(-15deg) scale(1.2);
    color: #EA4335;
}
.footer-link {
    color: black;
    text-decoration: none;
}

@media (max-width: 1080px) {
    .footer {
        height: 20vh;
    }
    .footer-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
    }
}